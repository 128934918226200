import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 5
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: CONCLUSION`,
      }}
    >
      <Quiz
        quizNumber={"FIVE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={
          "What should coaches reinforce with female athletes to push against gender stereotypes?"
        }
        answers={[
          `Female athletes have to be “masculine” and “play like the boys” to excel.`,
          "Female athletes will get bulky if they lift weights.",
          "Female athletes should obsess more about their attractiveness than their skills.",
          "Female athletes should care more about what their bodies can do than what they look like.",
        ]}
        correctAnswerNumber={3}
        answerDetail={`Studies have shown that feelings of physical competence that come from participation in sports help to boost overall self-esteem, which gives girls ownership of their bodies and a stronger sense of their identity. Girls who play sports are less likely to want to change their image.`}
        nextLink={nextLink}
        nextText={"RESULTS"}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 5, 6)
